/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './footer.css';
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";

const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo">Bhargav Naidu Gedela</a>

            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#work">Work</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.facebook.com/bhargavnaidu.gedela.1"><FaFacebookF /></a>
                <a href="https://www.instagram.com/bhargavnaidu.gedela/"><FiInstagram /></a>
                <a href="https://twitter.com/gedela_bhargav"><IoLogoTwitter /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; Bhargav Naidu Gedela. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer