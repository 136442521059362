import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";

const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a href="https://in.linkedin.com/in/bhargav-naidu-gedela-b3a2b61b4" target="_blank" rel="noreferrer"><BsLinkedin /></a>
            <a href="https://github.com/bhargavnaidu22/" target="_blank" rel="noreferrer"><FaGithub /></a>
            <a href="https://g.dev/bhargavnaidu" target="_blank" rel="noreferrer"><MdDeveloperMode /></a>
        </div>
    )
}

export default HeaderSocials