/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import './about.css'
import ME from '../../assets/bhargav-me.JPG'
import { FaAward } from "react-icons/fa"
// import { FiUsers } from "react-icons/fi"
// import { VscFolderLibrary } from "react-icons/vsc"

const About = () => {
    return (
        <section id="about">
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            <FaAward className="about_icon"/>
                            <h5>Bachelor of Technology</h5>
                            <small>7.63</small>
                            <br/>
                            <small>GMR Institute of Technology</small>
                        </article>

                        <article className="about__card">
                            <FaAward className="about_icon"/>
                            <h5>Intermediate</h5>
                            <small>8.63</small>
                            <br/>
                            <small>Sri Chaitanya Junior College</small>
                        </article>
                        
                        <article className="about__card">
                            <FaAward className="about_icon"/>
                            <h5>SSC</h5>
                            <small>9.3</small>
                            <br/>
                            <small>Bhashyam Public School</small>
                        </article>
                    </div>

                    <p>To be a key player and attain a challenging research position in a competitive software 
development company where I can rise to my highest potential in terms of creativity, 
dedication & motivation, thereby achieving team goals on a global level. </p>

                    <a href="#contact" className="btn btn-primary">Let's Talk</a>
                </div>
            </div>
        </section>
    )
}

export default About