import React from "react";
import './services.css';
import { BiCheck } from "react-icons/bi";

const Services = () => {
    return(
        <section id="services">
            <h5>What I Know</h5>
            <h2>Frameworks</h2>

            <div className="container service__container">
                <article className="service">
                    <div className="service__head">
                        <h3>Frontend</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Android (xml)</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>React</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Redux</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Axios</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Tailwind</p>
                        </li>
                    </ul>
                </article>
                {/* END OF FRONT END */}

                <article className="service">
                    <div className="service__head">
                        <h3>Backend</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Android (kotlin)</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Node js</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Express</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Rest</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Postman</p>
                        </li>
                    </ul>
                </article>
                {/* END OF BACK END */}

                <article className="service">
                    <div className="service__head">
                        <h3>DEVOPS</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Spring Boot</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Docker</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>CI/CD Pipelines</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Gitlab Runner</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Kubernets</p>
                        </li>
                    </ul>
                </article>
                {/* END OF DEVOPS */}
            </div>
        </section>
    )
}

export default Services