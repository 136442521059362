import React from "react";
import './work.css';
import { BsPatchCheckFill } from "react-icons/bs";

const Work = () => {
    return (
        <section id="work">
            <h5>What i have done</h5>
            <h2>Work Experience</h2>

            <div className="container work__container">
                <article className="work">
                    <div className="work__head">
                        <h3>Jitsi</h3>
                    </div>

                    <ul className="work__list">
                        <p>As a Jitsi Developer at Revidd Company,
                            I was responsible for designing, developing, and maintaining Jitsi-based video conferencing solutions.
                            My role involved the following responsibilities:
                        </p>
                        <li>
                        <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Conducting performance testing and optimization of Jitsi-based solutions to ensure smooth and reliable video conferencing experiences.</p>
                        </li>
                        <li>
                        <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Troubleshooting and resolving technical issues related to Jitsi installations and integrations.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Designing and developing custom Jitsi integrations and extensions to meet specific client needs.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p> My work as a Jitsi Developer has given me a deep understanding of the platform and its potential for creating reliable and scalable video conferencing solutions.</p>
                        </li>
                    </ul>
                </article>

                <article className="work">
                    <div className="work__head">
                        <h3>Java Backend</h3>
                    </div>

                    <ul className="work__list">
                        <p>
                            As a Java Developer at Revidd Company,
                            I was responsible for designing, developing, and maintaining Micro services using Spring Boot and related technologies.
                            My role involved the following responsibilities:
                        </p>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Developing and maintaining RESTful APIs using Spring Boot, and integrating them with Redis, RabbitMQ, and MongoDB for caching, messaging, and data storage purposes.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Designing and implementing search functionality using Elastic Search, and integrating it with Spring Boot for efficient and accurate search results.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>GraphQL queries and mutations.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Implementing payment functionality using Stripe, and integrating it with Spring Boot to enable secure and seamless payment processing.</p>
                        </li>
                        <li>
                            <BsPatchCheckFill className="experience__details-icon"/>
                            <p>Generating reports and exporting data using Jasper Reports, and integrating it with Spring Boot for easy and customizable report generation.</p>
                        </li>
                    </ul>
                </article>

            </div>
        </section>
    )
}

export default Work